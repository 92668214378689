import React from 'react';
import Avatar from '../Avatar';
import moment from 'moment';
import { useClient } from 'jsonapi-react';
import getIcon from 'app/helpers/getIcon';
import { useTranslation } from 'react-i18next';

export default function Notification(props) {
  const { t } = useTranslation();
  const { notification, notifications, setNotificationCount, setNotifications, notificationCount } = props;
  const client = useClient();
  moment.locale(t('language.language'));
  async function handleNotificationRead() {
    const { data, error } = await client.mutate('update_all/notifications', {
      notifications: notification['related-notification-ids'].map(id => ({ id: id, unread: false }))
    });

    if (!error) {
      setNotificationCount(notificationCount - 1);
      setNotifications(notifications.map(n => (n.id === notification.id ? { ...n, unread: false } : n)));
    }
    window.location.replace(notification?.url);
  }

  function senderNames() {
    let names = notification?.senders.map(sender => sender?.name).slice(0, 4);
    return notification?.senders?.length > 4 ? names.join(', ') : names.slice(0, -1).join(', ') + ' e ' + names.slice(-1);
  }

  console.log(notification?.senders);

  return (
    <>
      <div
        onClick={() => handleNotificationRead()}
        className={`notification notification--center ${notification.unread ? 'unread' : 'read'}`}
      >
        <div className="notification__header">
          <div className="notification__icon notification__icon--sm">{getIcon(notification.icon)}</div>
        </div>

        <div className="notification__avatar-stack">
          {notification?.senders?.length == 1 ? (
            <Avatar
              className="avatar--md"
              src={notification?.senders[0]?.image?.url}
              alt={notification?.senders[0]?.name}
            />
          ) : (
            <>
              <Avatar
                className="avatar--sm"
                src={notification?.senders[0]?.image?.url}
                alt={notification?.senders[0]?.name}
              />
              <Avatar
                className="avatar--sm"
                src={notification?.senders[1]?.image?.url}
                alt={notification?.senders[1]?.name}
              />
            </>
          )}
        </div>

        <div className="notification__body">
          <h4 className="notification__title">
            {notification?.senders?.length >= 2 ? senderNames() : notification?.senders[0]?.name}
            {notification?.senders?.length > 4 && <span className="notification__more"> +{notification?.senders?.length - 4}</span>}
          </h4>
          <p className="notification__text">
            {notification.body && (
              <>
                {notification.body.split('*f6$').map((n, i) => {
                  return n[0] == '>' ? (
                    <a
                      href={notification.url}
                      key={i}
                    >
                      {n.substring(1, n.length)}
                    </a>
                  ) : (
                    n
                  );
                })}
              </>
            )}
          </p>
        </div>

        <div className="notification__footer">
          <span className="notification__datetime">{moment(notification['created-at']).fromNow()}</span>
        </div>
      </div>
    </>
  );
}
