import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { FiChevronLeft, FiCalendar, FiInfo } from 'react-icons/fi';
import EssayCover from 'app/images/placeholders/show-course.jpg';
import mockImg from './mockImg.png';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { statusBadgeColor, statusBadgeText } from '../utils/BadgeEssay';
import Modal, { SimpleModal } from 'app/components/Modal';
import EssaySendTips from '../EssaySend/EssaySendTips';
import Onboarding from 'app/components/Onboarding';
import styles from './styles.module.scss';
import Disclaimer from 'app/screens/Dashboard/disclaimer';

export default function EssayAbout({ essayId, uri }) {
  const client = useClient();
  const [essay, setEssay] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const getEssay = async () => {
    const { data, error } = await client.fetch(`/essays/${essayId}`);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setEssay(data);
    }
  };

  useEffect(() => getEssay(), []);

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/redacao',
        name: 'Redação',
        isActive: false
      },
      {
        route: uri,
        name: essay?.title ? essay?.title : essay?.topic,
        isActive: true
      }
    ]
  };

  const disclaimerData = [{ name: '', description: 'Sua redação está sendo corrigida', type: 'info' }];

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        <div className="course-about u-mb-4">
          <div
            className="show-course-banner"
            style={{
              backgroundImage: `url(${null || EssayCover})`
            }}
          >
            <div className="show-course-banner__inner">
              <div className="show-course-banner__container">
                <span
                  title={statusBadgeText[essay?.status]}
                  className={`badge badge--tiny ${statusBadgeColor?.[essay?.status]}`}
                >
                  {statusBadgeText[essay?.status]}
                </span>
                <div
                  title={essay?.title ? essay?.title : essay?.topic}
                  className="show-course-banner__title"
                >
                  {essay?.title ? essay?.title : essay?.topic}
                </div>

                {((essay && essay?.status === 'accepting_proposal') || essay?.status === 'minimum_length_not_satisfied' || (essay?.status === 'ocr_review' && essay?.['essay-student']?.['submission_order'] < 3)) && (
                  <div className="show-course-banner__cta">
                    <Link
                      className="btn btn--wide btn--outline"
                      to={`/redacao/enviar/${essay?.id}`}
                    >
                      Enviar a redação
                    </Link>
                  </div>
                )}
                {essay?.status === 'annulled' && (
                  <div className="show-course-banner__cta">
                    <Link
                      className="btn btn--wide btn--primary"
                      to={`/redacao/consulta/${essay?.id}/${essay?.['essay-student']?.id}`}
                    >
                      Acessar redação
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          {essay?.status === 'processing' && (
            <div
              className="disclaimer-wrapper"
              style={{ justifyContent: 'center' }}
            >
              <div className="disclaimer-wrapper__disclaimer-right">
                <span className="shortcut-widget__icon">
                  <FiInfo />
                </span>
                <span className="disclaimer-wrapper__title">Sua redação está sendo corrigida. O resultado estará disponível aqui em breve.</span>
              </div>
            </div>
          )}

          <div className={styles['badge-wrapper']}>
            <div className="badge--redaction">
              <span>
                <FiCalendar className="card__icon" />
                Entregue a redação até <br />
                <strong>{moment(essay?.['delivery-date']).format('DD/MM/YYYY')}</strong>
              </span>
            </div>
            <div className="badge--redaction">
              <span>
                Essa redação permite <br />
                <strong>{essay?.['allow-multiple-submissions'] ? '3 envios' : '1 envio'} para correção</strong>
              </span>
            </div>
          </div>

          <div className="course-about__header">
            <h1 className="course-about__title">Enunciado</h1>

            <p
              className="course-about__description"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {essay?.statement}
            </p>

            <p className="course-about__description">
              Tema: {essay?.topic}
              <br />
              Título: <strong>{essay?.title}</strong>
            </p>
          </div>

          <div className="course-about__body">
            <h2 className="course-about__title">Textos motivadores</h2>
            {essay?.['essay-motivator-texts']?.map((item, index) => {
              return (
                <article>
                  <h3 className="course-about__subtitle">Texto {index + 1}</h3>
                  <p
                    className="course-about__description"
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  />
                  {/* <p className="course-about__description">Disponível em: https://brasilescola.uol.com.br/portugues/preconceito-linguistico.htm. Acesso em: 19 ago. 2022 (adaptado).</p> */}
                </article>
              );
            })}
          </div>
        </div>

        <SimpleModal
          show={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <EssaySendTips essayId={essayId} />
        </SimpleModal>
      </main>
      <button
        className={`${styles['send-tips']} fadeInRight`}
        onClick={() => setOpenModal(true)}
      >
        <FiInfo className={styles['send-tips-icon']} />
        Como enviar minha redação?
      </button>
    </>
  );
}
