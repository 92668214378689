import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { navigate, useParams } from '@reach/router';
import { useFormik } from 'formik';
import 'tippy.js/dist/tippy.css';
import TextareaAutosize from 'react-autosize-textarea';
import userAvatar from 'app/images/user-avatar.svg';
import { useClient, useQuery } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Modal, { SimpleModal } from 'app/components/Modal';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { useSession } from 'app/hooks/useSession';
import Competence from './Competence';
import Timeline from '../Timeline';
import styles from './styles.module.scss';
import Avatar from 'app/components/Avatar';
import imgTia from 'app/components/TIA/img/tia-avatar.png';
import lampIcon from 'app/images/icons/lamp-icon.png';
import { statusBadgeText } from '../utils/BadgeEssay';
import TabNavBar from './TabNavBar';
import heic2any from 'heic2any';

export default function EssayCorrection({ studentId, essayId, uri }) {
  const client = useClient();
  const { session } = useSession();
  const { t } = useTranslation();
  const [studentEssay, setStudentEssay] = useState({});
  const [initialIndex, setInitialIndex] = useState(null);
  const [finalIndex, setFinalIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [observation, setObservation] = useState('');
  const [show, setShow] = useState(false);
  const [deletedCorrections, setDeletedCorrections] = useState([]);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [parsedText, setParsedText] = useState('');
  const [multipleSubmissions, setMultipleSubmissions] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [activeSubmission, setActiveSubmission] = useState(Number(studentId));
  const [selectedTab, setSelectedTab] = useState('redacao');
  const isAnnulled = studentEssay?.status === 'annulled';

  const { data: essay } = useQuery(`/essays/${essayId}/essay_students/${studentId}`);
  const { data: essayAnnulments } = useQuery(studentEssay?.status && !isAnnulled ? `essay_annulments` : null);

  const getEssayData = async () => {
    const { data, error } = await client.fetch(`/essays/${essayId}`);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setMultipleSubmissions(data?.['allow-multiple-submissions']);
      if (data?.['allow-multiple-submissions'] === true) {
        const { data } = await client.fetch(`/essays/${essayId}/users/${essay?.user?.id}/essay_students`);
        setSubmissions(data);
      }
    }
  };

  useEffect(() => {
    getEssayData();
    setStudentEssay(essay);
    if (essay && ['unreviewed'].includes(essay?.status)) {
      client.mutate(['essays', essayId, 'essay_students', studentId], { status: 'reviewing' });
    } else if (essay && ['reviewing'].includes(essay?.status) && essay?.corrector?.id && essay?.corrector?.id !== session?.user?.id) {
      Swal.fire({
        title: 'Ops!',
        text: 'Outro professor está corrigindo essa redação',
        confirmButtonText: 'OK'
      }).then(res => {
        if (res.isConfirmed) {
          navigate(`/redacao/acompanhamento/${essayId}`);
        }
      });
    }
  }, [essay]);

  const switchDigital = useFormik({
    initialValues: {
      digital: true,
      competence: 'c1'
    }
  });

  useEffect(() => {
    if (studentEssay && studentEssay?.status === 'annulled') {
      switchDigital.setFieldValue('digital', false);
    }
  }, [studentEssay]);

  const essayForm = useFormik({
    initialValues: {
      suggestion_to_improve: '',
      body: '',
      essay_student_competencies_attributes: [],
      essay_student_corrections_attributes: []
    }
  });

  const competencies = [
    { name: 'Competencia 1', description: 'Domínio da Norma Culta da Língua Portuguesa' },
    { name: 'Competencia 2', description: 'Compreensão e Aplicação Interdisciplinar do Tema Proposto' },
    { name: 'Competencia 3', description: 'Seleção e Organização Lógica de Argumentos' },
    { name: 'Competencia 4', description: 'Construção Coesa e Coerente da Argumentação' },
    { name: 'Competencia 5', description: 'Proposição de Intervenção Respeitosa aos Direitos Humanos' }
  ];

  useEffect(() => {
    if (studentEssay) {
      essayForm.setValues({
        body: studentEssay.body,
        suggestion_to_improve: studentEssay.suggestion_to_improve,
        essay_student_corrections_attributes: studentEssay?.corrections,
        essay_student_competencies_attributes: studentEssay?.competencies
      });
    }

    if (studentEssay && studentEssay.essay_image && studentEssay.essay_image.url) {
      if (studentEssay.essay_image.url.includes('.heic') || studentEssay.essay_image.url.includes('.heif')) {
        convertHeicOrHeifToJpg(studentEssay.essay_image.url).then(url => {
          setStudentEssay(prevEssay => ({
            ...prevEssay,
            essay_image: { url }
          }));
        });
      }
    }
  }, [studentEssay]);

  const handleSubmitCorrection = () => {
    if (initialIndex !== null && finalIndex !== null) {
      essayForm.setFieldValue(`essay_student_corrections_attributes`, [...essayForm.values.essay_student_corrections_attributes, { body: observation, start_index: initialIndex, end_index: finalIndex }]);
    }
  };

  const sendEvaluation = async () => {
    if (isAnnulled) {
      const { data: essayUpdated, error } = await client.mutate(['essays', essayId, 'essay_students', studentId], {
        essay_student_content_attributes: {
          suggestion_to_improve: essayForm.values.suggestion_to_improve
        }
      });

      if (error) {
        toast.error('Erro ao avaliar redação');
      } else {
        toast.success('Correção enviada!');
        setStudentEssay(prev => ({ ...prev, suggestion_to_improve: essayUpdated.suggestion_to_improve }));
      }
    } else {
      const auxValues = { ...essayForm.values };
      //auxValues.id = studentEssay.essay_student_content_id;
      auxValues.essay_student_corrections_attributes = [...auxValues.essay_student_corrections_attributes, ...deletedCorrections];
      auxValues.essay_student_competencies_attributes = essayForm.values.essay_student_competencies_attributes.map(item => ({
        competency_id: String(item.competency_id)
      }));

      //Remove id
      auxValues.essay_student_corrections_attributes = auxValues.essay_student_corrections_attributes.map(item => {
        const { id, ...itemWithoutId } = item;
        return itemWithoutId;
      });

      const { error } = await client.mutate(['essays', essayId, 'essay_students', studentId], { essay_student_content_attributes: { ...auxValues } });

      if (error) {
        toast.error('Erro ao avaliar redação');
      } else {
        toast.success('Correção enviada!');
        navigate(`/redacao/acompanhamento/${essayId}`);
      }
    }
  };

  const convertHeicOrHeifToJpg = async imageUrl => {
    if (!imageUrl) {
      return null;
    }
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const convertedBlob = await heic2any({ blob });

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;

        setStudentEssay(prevEssay => ({
          ...prevEssay,
          essay_image: { url: base64data }
        }));
      };
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error('Erro ao converter imagem HEIC:', error);
    }
  };

  useEffect(() => {
    renderTextWithMarks();
  }, [essayForm.values.essay_student_corrections_attributes]);

  const renderTextWithMarks = () => {
    const text = essayForm.values.body;
    const selections = essayForm?.values.essay_student_corrections_attributes?.sort((a, b) => a.start_index - b.start_index);
    if (selections === undefined || selections.length === 0) {
      return text;
    } else {
      let new_text = '';
      let acumulator = '';
      let selectionIndex = 0;
      let counter = 0;

      text?.split('').forEach((c, i) => {
        if (c === '<' && text[i + 2] === '>') {
          counter += 3;
        } else if (c === '<' && text[i + 3] === '>') {
          counter += 4;
        }

        if (i >= selections[selectionIndex]?.start_index + counter && i < selections[selectionIndex]?.end_index + counter) {
          acumulator += c;
        } else if (i === selections[selectionIndex]?.end_index + counter) {
          new_text += `<mark>${acumulator}</mark>${c}`;
          acumulator = '';
          if (selections.length > selectionIndex) {
            selectionIndex += 1;
          }
        } else {
          new_text += c;
        }
      });

      const replaceItems = [
        ['<p<mark> >', '<p><mark> '],
        ['<p<mark>>', '<p><mark>'],
        ['<mark><p>', '<p><mark>'],
        ['</p></mark>', '</mark></p>']
      ];
      replaceItems.forEach(item => {
        new_text = new_text.replace(item[0], item[1]);
      });

      setParsedText(new_text);
    }
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    const start = selection.anchorOffset;
    const end = selection.focusOffset;

    const anchorNode = selection.anchorNode.data;
    const rawText = selection.anchorNode.parentNode.parentNode.innerText;
    const previousChars = rawText.split(anchorNode)[0].length;

    if (start !== end) {
      setShowModal(true);
      setFinalIndex(end + previousChars);
      setInitialIndex(start + previousChars);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmitCorrection();
      setShowModal(false);
      setInitialIndex(null);
      setFinalIndex(null);
      setObservation('');
    }
  };

  const handleDeleteCorrection = (startIndex, endIndex) => {
    Swal.fire({
      title: 'Tem certeza que deseja excluir a marcação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('button.delete'),
      cancelButtonText: t('button.cancel'),
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        const filteredCorrections = essayForm?.values?.essay_student_corrections_attributes?.filter(item => {
          if (item.start_index !== startIndex && item.end_index !== endIndex) {
            return true;
          } else if (item.id) {
            setDeletedCorrections(prevState => [...prevState, { ...item, _destroy: true }]);
          }
        });

        essayForm.setFieldValue(`essay_student_corrections_attributes`, filteredCorrections);
      }
    });
  };

  const handleOpenCancelEssayModal = () => {
    setIsOpenCancel(prev => !prev);
  };

  const handleSubmitCancel = async e => {
    e.preventDefault();

    const data = new FormData(e.target);
    const formProps = Object.fromEntries(data);

    if (!formProps.reason) {
      toast.error('Selecione um motivo');
      return;
    }

    const { data: annulmentData, error } = await client.mutate(['essays', essayId, 'essay_students', studentId], { essay_annulment_id: formProps.reason });

    if (error) {
      toast.error('Erro ao anular redação');
    } else {
      setStudentEssay(annulmentData);
      toast.success('Redação anulada com sucesso!');
      setIsOpenCancel(false);
    }
  };

  const essayStatus = {
    accepting_proposal: { text: 'Aceitando respostas', badgeColor: 'warning' },
    close_for_submissions: { text: 'Prazo encerrado', badgeColor: 'danger' },
    processing: { text: 'Em correção', badgeColor: 'warning' },
    unreviewed: { text: 'Em correção', badgeColor: 'warning' },
    reviewing: { text: 'Em revisão', badgeColor: 'warning' },
    reviewed: { text: 'Corrigida', badgeColor: 'success' },
    annulled: { text: 'Anulado', badgeColor: 'danger' }
  };

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/redacao',
        name: 'Redação',
        isActive: false
      },
      {
        route: `/redacao/acompanhamento/${essayId}`,
        name: essay?.topic,
        isActive: false
      },
      {
        route: uri,
        name: 'Correção',
        isActive: true
      }
    ]
  };

  const checkScore = score => {
    if (score <= 333) {
      return 'danger';
    } else if (score > 333 && score <= 666) {
      return 'warning';
    } else if (score > 666) {
      return 'success';
    }
  };

  useEffect(() => {
    getEssayData();
  }, [studentId]);

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />
      <TabNavBar
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        essayStatus={studentEssay?.status}
      />
      <div className={styles['page-content']}>
        {selectedTab === 'redacao' && (
          <>
            <div className={styles.aside}>
              {multipleSubmissions ? (
                <div className="widget">
                  <div
                    className="round-dg__user u-cursor-pointer u-mb-3"
                    onClick={() => navigate(`/usuario/metricas/${studentEssay?.user?.id}`)}
                  >
                    <img
                      className="avatar avatar--xs"
                      src={studentEssay?.user?.image || userAvatar}
                      alt="Imagem do usuário"
                    />

                    <div className="round-dg__user-inner">
                      <h3
                        className="round-dg__title"
                        title={studentEssay?.user?.name}
                      >
                        {studentEssay?.user?.name}
                      </h3>
                    </div>
                  </div>
                  <Timeline
                    submissions={submissions}
                    activeSubmission={activeSubmission}
                    setActiveSubmission={setActiveSubmission}
                    essayId={essayId}
                  />
                  {studentEssay?.['grammar_quality'] && (
                    <div className={`${styles['aside-footer']} ${styles['aside-footer-border']} u-mt-4`}>
                      <h2>Avaliação ortográfica</h2>
                      <span className={`square-tag square-tag--sm square-tag--${studentEssay?.['grammar_quality'] === 'Boa' ? 'success' : studentEssay?.['grammar_quality'] === 'Mediana' ? 'warning' : 'danger'}`}>
                        {studentEssay?.['grammar_quality']}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="widget">
                  <div className={styles['score-card']}>
                    <p className={`${styles['score-card-value']} ${styles[`score-card-value--${checkScore(studentEssay?.grade)}`]} u-mb-0`}>{studentEssay?.grade}</p>
                    <span>pontos</span>
                  </div>
                  <div className={styles['aside-footer']}>
                    <span className="badge badge--tiny">{statusBadgeText[essay?.status]}</span>
                    <p className="u-mb-0">em 01/01/2024</p>
                  </div>
                  {studentEssay?.['grammar_quality'] && (
                    <div className={`${styles['aside-footer']} ${styles['aside-footer-border']} u-mt-4`}>
                      <h2>Avaliação ortográfica</h2>
                      <span className={`square-tag square-tag--sm square-tag--${studentEssay?.['grammar_quality'] === 'Boa' ? 'success' : studentEssay?.['grammar_quality'] === 'Mediana' ? 'warning' : 'danger'}`}>
                        {studentEssay?.['grammar_quality']}
                      </span>
                    </div>
                  )}
                </div>
              )}
              <button
                className={`btn--primary btn--small ${styles['annul-btn']} ${styles['annul-btn--only-xxl']} u-mt-4`}
                onClick={handleOpenCancelEssayModal}
              >
                Anular redação
              </button>
            </div>
            <div className={styles['page-content-main']}>
              <div className={` ${styles['tia-container']} u-mb-4`}>
                <Avatar
                  className={styles['tia-container-avatar']}
                  src={imgTia}
                  alt="T.I.A"
                  data-tooltip-id="tia"
                  data-tooltip-place="top-start"
                  data-tooltip-variant="light"
                />
                <div className={styles['tia-container-text-balloon']}>
                  <div className="widget__header">
                    <div className={styles['tia-container-title']}>
                      <h2>Sugestões da T.I.A para melhorar sua redação</h2>
                      <img
                        src={lampIcon}
                        className={styles['tia-container-title-icon']}
                      />
                    </div>
                    <p>{studentEssay?.['suggestion_to_improve']}</p>
                  </div>
                </div>
              </div>

              <div className="skill-evaluation">
                <div className="skill-evaluation__header">
                  <h1 className="skill-evaluation__title">Sugestão para avaliação de competências</h1>
                </div>

                <div className="skill-evaluation__essay">
                  {studentEssay?.status !== 'annulled' && (
                    <span
                      className="badge badge--redaction"
                      style={{ height: 'auto', whiteSpace: 'normal' }}
                    >
                      Para adicionar observações ao texto, basta selecionar o trecho desejado{' '}
                    </span>
                  )}

                  <div className="skill-evaluation__essay-header">
                    <h2 className="skill-evaluation__essay-title">Redação avaliada</h2>
                    {studentEssay?.status !== 'annulled' && (
                      <div className="form__check form__switch u-mb-0">
                        <input
                          className="form__check-input"
                          id="digital"
                          name="digital"
                          type="checkbox"
                          checked={switchDigital.values.digital}
                          onChange={() => {
                            switchDigital.setFieldValue('digital', !switchDigital.values.digital);
                          }}
                        />
                        <label htmlFor="digital">{switchDigital.values.digital ? 'Digital' : 'Cursiva'}</label>
                      </div>
                    )}
                  </div>

                  {switchDigital.values.digital ? (
                    <div className="skill-evaluation__essay-body">
                      <div
                        className="skill-evaluation__essay-text"
                        style={{ overflow: 'visible' }}
                      >
                        <span
                          className="skill-evaluation__essay-raw_text"
                          // onMouseDown={() => handleMouseDown(index)}
                          onMouseUp={handleMouseUp}
                          dangerouslySetInnerHTML={{ __html: essayForm.values.body }}
                        />
                        <span
                          className="skill-evaluation__essay-mark"
                          dangerouslySetInnerHTML={{ __html: parsedText }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <img
                        className="u-cursor-pointer"
                        src={studentEssay?.essay_image?.url}
                        alt="prova digitalizada"
                        onClick={() => setShow(true)}
                      />
                      <SimpleModal
                        show={show}
                        onClose={() => setShow(false)}
                        contentClassName="simple-modal__content--lg"
                      >
                        <img
                          src={studentEssay?.essay_image?.url}
                          alt="prova digitalizada"
                        />
                      </SimpleModal>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={`${styles.aside} ${styles['aside-competences']}`}>
              <button
                onClick={sendEvaluation}
                className="btn btn--wide btn--full btn--primary u-mb-3"
              >
                Enviar para o estudante
              </button>
              <div className="widget">
                <button
                  className={`btn btn--outline btn--small ${styles['annul-btn']}`}
                  onClick={handleOpenCancelEssayModal}
                >
                  Anular redação
                </button>
                <h2 className="widget__title">Avaliação de competências</h2>
                <p>Pontuação total: {studentEssay?.grade} pontos</p>
                {essayForm?.values?.essay_student_competencies_attributes?.map((item, index) => (
                  <Competence
                    competence={item}
                    item={competencies[index]}
                    form={essayForm}
                    studentEssay={studentEssay}
                    switchDigital={switchDigital}
                    index={index}
                  />
                ))}
              </div>
            </div>
          </>
        )}
        {selectedTab === 'competencias' && (
          <>
            <button
              onClick={sendEvaluation}
              className="btn btn--wide btn--primary"
            >
              Enviar para o estudante
            </button>
            <div className={styles['competences-tab']}>
              <div className={`${styles.competences}`}>
                <h2 className="widget__title  u-mb-3">Avaliação de competências</h2>
                <p>Pontuação total: {studentEssay?.grade} pontos</p>
                <div className={styles['competences-list']}>
                  {essayForm?.values?.essay_student_competencies_attributes?.map((item, index) => (
                    <Competence
                      competence={item}
                      item={competencies[index]}
                      form={essayForm}
                      studentEssay={studentEssay}
                      switchDigital={switchDigital}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        show={isOpenCancel}
        onClose={() => setIsOpenCancel(false)}
      >
        <div style={{ width: '100%', borderBottom: '1px solid rgba(223, 223, 223, 0.5)', paddingBottom: 20, marginBottom: 20 }}>
          <h2 className="form__title">Anular redação</h2>
          <span>Selecione o motivo da anulação:</span>
        </div>

        <form onSubmit={handleSubmitCancel}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, borderBottom: '1px solid rgba(223, 223, 223, 0.5)', paddingBottom: 20, marginBottom: 20 }}>
            {essayAnnulments?.map(item => (
              <label key={item.id}>
                <input
                  id="reason"
                  type="radio"
                  value={item.id}
                  name="reason"
                />
                {item.body}
              </label>
            ))}
          </div>

          <button
            type="submit"
            className="btn btn--primary btn--full"
          >
            Salvar
          </button>
        </form>
      </Modal>

      <SimpleModal
        show={showModal}
        onClose={() => setShowModal(false)}
        hideCloseButton
      >
        <label className="form__label">Observação</label>
        <textarea
          value={observation}
          onChange={e => setObservation(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </SimpleModal>
    </main>
  );
}
