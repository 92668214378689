import TextareaAutosize from 'react-autosize-textarea';
import styles from './styles.module.scss';

export default function Competence({ competence, item, form, studentEssay, switchDigital, index }) {
  function valuesToArray(obj) {
    return Object.keys(obj).map(function (key) {
      return obj[key];
    });
  }

  const checkScore = (score, maxScore) => {
    const oneThird = maxScore / 3;
    const twoThirds = oneThird * 2;

    if (score <= oneThird) {
      return 'danger';
    } else if (score > oneThird && score <= twoThirds) {
      return 'warning';
    } else if (score > twoThirds) {
      return 'success';
    }
  };

  const competenceSelected = form?.values.essay_student_competencies_attributes?.find(item => item?.slug === competence.slug);

  return (
    <div className={`${styles['competences-card']} u-mt-3`}>
      <span className={styles['competences-card-title']}>{item.name}</span>
      <h2 className="widget__title u-mb-2">{item.description}</h2>
      <div className={styles['competences-card-level']}>
        <span>Nível {competence?.level}</span>
        <select
          id="level"
          name="level"
          className="form__select"
          value={form?.values?.essay_student_competencies_attributes?.[index]?.competency_id}
          onChange={e => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedScore = selectedOption.getAttribute('data-score');
            form.setFieldValue(`essay_student_competencies_attributes[${index}].competency_id`, e.target.value);
            form.setFieldValue(`essay_student_competencies_attributes[${index}].score`, parseInt(selectedScore));
          }}
        >
          {valuesToArray(studentEssay?.competencies_list?.[competenceSelected?.name])?.map((item, index) => {
            return (
              <option
                key={item.id}
                value={item.id}
                data-score={item?.score}
              >
                Nível {index}
              </option>
            );
          })}
        </select>

        <TextareaAutosize
          name="commentary"
          style={{ minHeight: 60, marginTop: 8, border: 'none', boxShadow: 'none' }}
          disabled
          value={valuesToArray(studentEssay?.competencies_list?.[competenceSelected?.name]).find(comp => comp.id === Number(competence.competency_id))?.comment}
        />
      </div>
      <span className={`square-tag square-tag--full square-tag--md square-tag--${checkScore(competence?.score, competence?.['max_grade'])} ${styles['competences-card-score']}`}>
        <strong>{competence?.score}</strong>/{competence?.['max_grade']} pts
      </span>
    </div>
  );
}
