import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import useReportAbilities from 'app/hooks/useReportAbilities';
import BreadCrumbs from 'app/components/BreadCrumbs';
import KpiIcon from './KpiIcon';

export default function MetricsEmbed(props) {
  const { selectedTab, uri } = props;
  const client = useClient();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const { reportAbilities, getReportAbilities } = useReportAbilities();

  console.log({ reportAbilities });
  const getReports = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('/embed_reports');
    if (error) {
      toast.error('Erro ao buscar relatórios');
    } else {
      setReports(data);
    }
    setLoading(false);
  };

  const getInfo = async () => {
    const { data, error } = await client.fetch('/reports/learning_systems/general');
    if (error) {
      toast.error('Erro ao obter dados');
    } else {
      setInfo(data);
    }
  };

  useEffect(() => {
    getReports();
    getInfo();
    getReportAbilities();
  }, []);

  const ReportCard = ({ route, title, description }) => {
    let reportCardClass = 'report-card';

    switch (route) {
      case '/relatorio/progresso-de-curso':
        reportCardClass = [reportCardClass, 'report-card--monitor'].join(' ');
        break;
      case '/relatorio/acesso':
        reportCardClass = [reportCardClass, 'report-card--globe'].join(' ');
        break;
      case '/relatorio/cadastro':
        reportCardClass = [reportCardClass, 'report-card--user'].join(' ');
        break;
      case '/relatorio/funil-de-acessos':
        reportCardClass = [reportCardClass, 'report-card--mouse-pointer'].join(' ');
        break;
      case '/relatorio/redacao':
        reportCardClass = [reportCardClass, 'report-card--pencil'].join(' ');
        break;

      default:
        break;
    }

    return (
      <div
        className={reportCardClass}
        onClick={() => navigate(route)}
      >
        <h2
          className="report-card__title"
          title={title}
        >
          {title}
        </h2>
        <p className="report-card__subtitle">{description}</p>
      </div>
    );
  };

  const frontReports = [];

  if (reportAbilities?.['cadastro-e-notas']) {
    frontReports.push({ route: `/relatorio/seduc-pi/geral`, title: 'Simulados e notas', description: 'Acompanhamento de simulados e notas no último simulado' });
  }

  if (reportAbilities?.['aulas-ao-vivo']?.['media-de-satisfacao'] || reportAbilities?.['aulas-ao-vivo']?.['ranking']) {
    frontReports.push({ route: `/relatorio/aula-ao-vivo`, title: 'Aulas ao vivo', description: 'Relatórios de satisfação do conteúdo das aulas' });
  }

  if (reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['mapa-de-usuarios-cadastrados'] || reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['ranking']) {
    frontReports.push({
      route: `/relatorio/cadastro`,
      title: 'Cadastros',
      description: 'Visão global dos cadastros de usuários'
    });
  }
  if (reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['horarios-mais-acessados'] || reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['mapa-de-calor']) {
    frontReports.push({
      route: `/relatorio/acesso`,
      title: 'Mapeamento de Acesso',
      description: 'Região geográfica e horários de maior atividade'
    });
  }

  if (reportAbilities?.['progresso-de-curso']) {
    frontReports.push({
      route: `/relatorio/progresso-de-curso`,
      title: 'Progresso  dos Cursos',
      description: 'Acompanhe o andamento dos estudantes nos cursos.'
    });
  }

  if (reportAbilities?.['perguntas']) {
    frontReports.push({
      route: `/relatorio/relatorio-de-perguntas`,
      title: 'Relatório de perguntas',
      description: 'Avalie a dificuldade de questões e pontos de atenção durante explicação'
    });
  }

  if (reportAbilities?.['respostas-avaliacoes']) {
    frontReports.push({
      route: `/relatorio/quiz`,
      title: 'Relatório de respostas de avaliações',
      description: 'Objetivo: Identificar quais alunos, escolas e GRE responderam ás avaliações '
    });
  }
  if (reportAbilities?.['acessos-aulas-ao-vivo']) {
    frontReports.push({
      route: `/relatorio/acessos-aulas-ao-vivo`,
      title: 'Relatório de acessos às aulas ao vivo',
      description: 'Acompanhe o acesso de escolas e GREs'
    });
  }

  // frontReports.push({
  //   route: `/relatorio/enem`,
  //   title: 'Enem',
  //   description: 'Acompanhe as inscrições para o exame'
  // });

  if (reportAbilities?.funil) {
    frontReports.push({
      route: `/relatorio/funil-de-acessos`,
      title: 'Funil de Acessos',
      description: 'Mostra a movimentação dos alunos em cursos e módulos.'
    });
  }

  if (reportAbilities?.['aulas-gravadas']) {
    frontReports.push({
      route: `/relatorio/aulas-gravadas`,
      title: 'Relatório de acessos às aulas gravadas',
      description: 'Acompanhe quais alunos, perfis, escolas ou GRE estão assistindo as aulas ao gravadas.'
    });
  }

  frontReports.push({
    route: `/relatorio/redacao`,
    title: 'Redações',
    description: 'Detalhamento do envio e pontuação dos estudantes'
  });

  const allReports = frontReports.concat(reports);

  const breadCrumbs = {
    title: 'Análise',
    nav: [
      {
        route: uri,
        name: 'Relatórios',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="report-kpi-wrapper">
        {info?.items?.map((item, index) => (
          <div
            className="report-kpi"
            key={item?.label}
          >
            <div className="report-kpi__header">
              <KpiIcon index={index} />
              <h4 className="report-kpi__title">{item?.label || ''}</h4>
            </div>
            <div className="report-kpi__body">
              <p className="report-kpi__value">{item?.value || ''}</p>
            </div>
          </div>
        ))}
      </div>

      <p>Acompanhe os índices de desempenho dos estudantes através de painéis de controle com indicadores individuais e coletivos. Selecione um painel para acessar as métricas</p>

      {reports?.length === 0 && loading && <Loader />}

      {allReports.length === 0 && !loading && (
        <EmptyState
          type="data"
          text="Nenhum relatório disponível"
        />
      )}

      <div className="report-card-wrapper">
        {reports?.map(item => (
          <ReportCard
            route={`/metricas/${selectedTab}/${item.id}`}
            title={item.label}
            description={item.description}
            key={item.id}
          />
        ))}

        {frontReports?.map(item => (
          <ReportCard
            route={item.route}
            title={item.title}
            description={item.description}
            key={item.route}
          />
        ))}
      </div>
    </main>
  );
}
