import React from 'react';
import medalImg from './img/medal.svg';
import medalPrimaryImg from './img/medalPrimary.svg';
import medalSuccessImg from './img/medalSuccess.svg';
import medalWarningImg from './img/medalWarning.svg';
import medalDangerImg from './img/medalDanger.svg';
import medalGoldImg from './img/medalGold.svg';
import MedalStack from './MedalStack';

export function Medal(props) {
  const { value, size, src } = props;

  let medal = 'medal';
  if (size === 'md') medal = [medal, 'medal--md'].join(' ');
  if (size === 'challenge') medal = [medal, 'medal--challenge'].join(' ');
  if (size === 'tia') medal = [medal, 'medal--tia'].join(' ');

  let srcImg = medalImg;
  if (src === 'primary') srcImg = medalPrimaryImg;
  if (src === 'success') srcImg = medalSuccessImg;
  if (src === 'warning') srcImg = medalWarningImg;
  if (src === 'danger') srcImg = medalDangerImg;
  if (src === 'gold') srcImg = medalGoldImg;

  return (
    <div className={medal}>
      <img
        className="medal__img"
        src={srcImg}
        alt="medalha"
      />
      {value !== '0' && <span className="medal__value">{value}</span>}
    </div>
  );
}

Medal.Stack = MedalStack;

export { Medal as default, MedalStack };
