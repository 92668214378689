import { Link } from '@reach/router';
import GameIcon from 'app/images/icons/game';
import SimulationIcon from 'app/images/icons/simulation-icon';
import { FiClipboard, FiInfo } from 'react-icons/fi';
import { FiStar } from 'react-icons/fi';

export default function Disclaimer({ alerts }) {
  const icon = type => {
    switch (type) {
      case 'challenge':
        return <GameIcon />;
      case 'project':
        return <FiClipboard />;
      case 'survey':
        return <FiStar />;
      case 'exam':
        return <SimulationIcon />;
      case 'info':
        return <FiInfo />;
    }
  };

  return (
    <>
      {alerts.map(item => (
        <div className="disclaimer-wrapper u-mb-3">
          <div className="disclaimer-wrapper__disclaimer-right">
            <span className="shortcut-widget__icon">{icon(item.type)}</span>
            <span className="disclaimer-wrapper__title">{item?.name}</span>
            <span>•</span>
            <span>{item?.description}</span>
          </div>

          {item?.['access_url'] && (
            <Link
              to={item.type === 'challenge' ? '/desafio-da-semana/desafios' : item?.['access_url']}
              className="btn btn--link"
            >
              Acessar
            </Link>
          )}
        </div>
      ))}
    </>
  );
}
