import React, { useEffect, useState } from 'react';
import { FiPlusCircle, FiX } from 'react-icons/fi';
import Select from 'react-select';

export default function ReportFilter(props) {
  const { data, currentFilters, setCurrentFilters, fetchInstitutions, fetchModules, setSelectedRegionals, setSelectedCourses, selectedRegionals, selectedCourses, setSelectedInstitutions, selectedInstitutions, setSelectedModules, setSelectedEssays } =
    props;
  const [show, setShow] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(null);

  const handleChange = async e => {
    //adicionar itens no filtro
    if (data[currentFilterIndex].name === 'Regional') {
      setSelectedRegionals(prevState => [...prevState, { value: e.value, label: e.label, type: 'regional' }]);
    }
    if (data[currentFilterIndex].name === 'Curso') {
      setSelectedCourses(prevState => [...prevState, { value: e.value, label: e.label, type: 'course' }]);
    }
    if (data[currentFilterIndex].name === 'Módulo') {
      setSelectedModules(prevState => [...prevState, { value: e.value, label: e.label, type: 'module' }]);
    }
    if (data[currentFilterIndex].name === 'Instituição') {
      setSelectedInstitutions(prevState => [...prevState, { value: e.value, label: e.label, type: 'institution' }]);
    }
    if (data[currentFilterIndex].name === 'Redação') {
      setSelectedEssays(prevState => [...prevState, { value: e.value, label: e.label, type: 'essay' }]);
    }
    setShowSelect(false);
  };

  const handleRemoveFilterItem = item => {
    //remover itens do filtro
    switch (item.type) {
      case 'course':
        setSelectedCourses(prevState => prevState.filter(c => c.value !== item.value));
        break;
      case 'module':
        setSelectedModules(prevState => prevState.filter(m => m.value !== item.value));
        break;
      case 'institution':
        setSelectedInstitutions(prevState => prevState.filter(i => i.value !== item.value));
        break;
      case 'regional':
        setSelectedRegionals(prevState => prevState.filter(r => r.value !== item.value));
        break;
      case 'essay':
        setSelectedEssays(prevState => prevState.filter(e => e.value !== item.value));
        break;
    }
  };

  useEffect(() => {
    // tratar condições pra fazer as requisições dos filtros seguintes
    if (fetchInstitutions) {
      if (selectedRegionals?.length > 0) {
        fetchInstitutions(`/institutions?filter[regional_id]=${selectedRegionals.map(item => item.value)}`);
      } else {
        fetchInstitutions();
      }
    }
  }, [selectedRegionals]);

  useEffect(() => {
    if (fetchModules) {
      if (selectedCourses?.length > 0) {
        fetchModules(`/learning_systems?filter[course_id]=${selectedCourses.map(item => item.value)}`);
      } else {
        fetchModules();
      }
    }
  }, [selectedCourses]);

  return (
    <div
      className={show ? 'report-filter report-filter--is-open' : 'report-filter'}
      onClick={() => setShow(!show)}
    >
      <div className="report-filter__inner">
        <button
          type="button"
          className="report-filter__toggle"
          onClick={() => setShowSelect(false)}
        >
          <FiPlusCircle /> Adicionar filtro
        </button>

        {show && (
          <div
            className="report-filter__options"
            onClick={() => setShowSelect(true)}
          >
            {data?.map((btn, i) => (
              <button
                type="button"
                className="report-filter__btn"
                onClick={() => setCurrentFilterIndex(i)}
                key={i}
              >
                {btn.name}
              </button>
            ))}
          </div>
        )}
      </div>

      {showSelect && (
        <div
          className="report-filter__select"
          onClick={e => e.stopPropagation()}
        >
          <Select
            openMenuOnFocus
            options={data[currentFilterIndex]?.options?.map(item => {
              return { value: item?.value, label: item?.label };
            })}
            className="react-multi-select report-form__select"
            classNamePrefix="react-multi-select"
            placeholder={data[currentFilterIndex]?.placeholder}
            noOptionsMessage={() => 'Sem opções'}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            onChange={handleChange}
          />
        </div>
      )}

      {currentFilters?.length > 0 && (
        <div
          className="report-filter__value-container"
          onClick={e => e.stopPropagation()}
        >
          {currentFilters?.map(badge => (
            <span
              className="report-filter__badge"
              key={badge?.value}
            >
              {badge?.label}{' '}
              <FiX
                className="report-filter__badge-icon"
                onClick={() => handleRemoveFilterItem(badge)}
              />
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
