import React from 'react';
import { ReactComponent as UserAvatar } from 'app/images/user-avatar.svg';

export default function Avatar(props) {
  const { src, alt, className, ...restProps } = props;

  return src ? (
    <img
      className={`avatar ${className}`}
      src={src}
      alt={alt || 'foto do usuário'}
      title={alt || 'foto do usuário'}
      {...restProps}
    />
  ) : (
    <UserAvatar
      className={`avatar ${className}`}
      title={alt || 'foto do usuário'}
    />
  );
}

export * from './AvatarStack';
