import React, { useEffect, useState } from 'react';
import MainNav from 'app/components/mainNav';
import Menu from 'app/components/Menu';
import CpfModal from 'app/components/CpfModal';
import { useSession } from 'app/hooks/useSession';
import PtsModal from 'app/screens/Users/PtsModal';
import ActionCableConsumer from 'app/utils/actionCableConsumer';

function PageWithLayouts(props) {
  const { layoutType, children } = props;
  const [showModal, setShowModal] = useState(false);
  const { session } = useSession();
  const [achievementData, setAchievementData] = useState(null);

  return (
    <div className={layoutType === 'mainLayout' ? 'main-screen' : 'main-screen main-screen--admin'}>
      {layoutType === 'mainLayout' && session && (
        <>
          <Menu />
          <MainNav />
          <CpfModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <PtsModal
            achievementData={achievementData}
            setAchievementData={setAchievementData}
          />

          <ActionCableConsumer
            channel="UserMilestoneChannel"
            setObjectChannel={setAchievementData}
            channelKey={'user_id'}
            channelId={session?.user?.id}
          />
        </>
      )}

      {children}
    </div>
  );
}

export default PageWithLayouts;
