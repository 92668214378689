import { AiOutlineTrophy } from 'react-icons/ai';
import moreMedal from 'app/images/more-medal.svg';

import userAvatar from '../../images/user-avatar.svg';
import FunctionCard from './FunctionCard';

export default function StudentMetricsView({ achievements, milestones, achievementRanking }) {
  const getMetricStyles = type => {
    switch (type) {
      case 'achievements':
        return { lightColor: '#ECE9F8', darkColor: '#7B63BF' };
      case 'missions':
        return { lightColor: '#FFECD8', darkColor: '#F99153' };
      case 'merit':
        return { lightColor: '#FFE7E5', darkColor: '#FF4C6F' };
    }
  };

  const dailyAchievements = achievements?.filter(item => {
    if (item.achievement.category === 'daily') {
      return true;
    }
  });

  const weeklyAchievements = achievements?.filter(item => {
    if (item.achievement.category === 'weekly') {
      return true;
    }
  });
  const meritAchievements = achievements?.filter(item => {
    if (item.achievement.category === 'merit') {
      return true;
    }
  });

  const porcentagem = milestones?.['milestone-level']?.['points-required'] ? (milestones.score / milestones['milestone-level']['points-required']) * 100 : 0;

  return (
    <div
      className="page"
      style={{ gap: '60px' }}
    >
      <div style={{ width: "55%" }}>
        <div className="widget">
          <div className="metrics-header">
            <div className="metrics-header-level">
              <div className="metrics-header-title">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill={true ? '#7B63BF' : '#BCC4D6'}
                >
                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                </svg>
                <span>Nível {milestones?.['milestone-level']?.['level-order']}</span>
              </div>
              <div className="metrics-header-title">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill={false ? '#7B63BF' : '#BCC4D6'}
                >
                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                </svg>
                <span style={{ color: true && '#BCC4D6' }}>Nível {milestones?.['milestone-level']?.['level-order'] + 1}</span>
              </div>
            </div>
            <div
              className="card__progress u-mb-0"
              style={{ width: '100%' }}
            >
              <div
                className="card__progress-container"
                style={{ margin: '12px 0' }}
              >
                <div
                  className="card__progress-bar"
                  role="progressbar"
                  style={{ width: `${porcentagem}%`, backgroundColor: '#6FCF97' }}
                  aria-valuenow={50}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
            <span
              style={{ fontWeight: 500 }}
              className="metrics-header-points"
            >
              <b>{milestones?.score}</b>/{milestones?.['milestone-level']?.['points-required']} pontos
            </span>
          </div>
          <div
            className="metrics--list"
            style={{ gap: '36px' }}
          >
            <div
              className="metrics"
              style={{ borderColor: getMetricStyles('achievements').lightColor }}
            >
              <span
                className="metrics__tag"
                style={{ backgroundColor: getMetricStyles('achievements').lightColor, color: getMetricStyles('achievements').darkColor }}
              >
                Conquistas Diárias
              </span>
              <div className="metrics--list">
                {dailyAchievements.map(item => {
                  return (
                    <FunctionCard
                      title={item.achievement.title}
                      description={item.achievement.description}
                      done={item.done}
                      currentCount={item?.['stages-completed']}
                      totalCount={item.achievement?.['stages-count']}
                      points={item.achievement?.['base-score']}
                      buttonClass="achievements"
                    />
                  );
                })}
              </div>
            </div>
            <div
              className="metrics"
              style={{ borderColor: getMetricStyles('missions').lightColor }}
            >
              <span
                className="metrics__tag"
                style={{ backgroundColor: getMetricStyles('missions').lightColor, color: getMetricStyles('missions').darkColor }}
              >
                MISSÕES DA SEMANA{' '}
              </span>
              <div className="metrics--list">
                {weeklyAchievements.map(item => {
                  return (
                    <FunctionCard
                      title={item.achievement.title}
                      description={item.achievement.description}
                      done={item.done}
                      currentCount={item?.['stages-completed']}
                      totalCount={item.achievement?.['stages-count']}
                      points={item.achievement?.['base-score']}
                      buttonClass="missions"
                    />
                  );
                })}
              </div>
            </div>
            <div
              className="metrics"
              style={{ borderColor: getMetricStyles('merit').lightColor }}
            >
              <span
                className="metrics__tag"
                style={{ backgroundColor: getMetricStyles('merit').lightColor, color: getMetricStyles('merit').darkColor }}
              >
                MÉRITO
              </span>
              <div className="metrics--list">
                {meritAchievements.map(item => {
                  return (
                    <FunctionCard
                      title={item.achievement.title}
                      description={item.achievement.description}
                      done={item.done}
                      currentCount={item?.['stages-completed']}
                      totalCount={item.achievement?.['stages-count']}
                      points={item.achievement?.['base-score']}
                      buttonClass="merit"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{width: "35%"}}>
        <div className="widget metrics__news">
          <span
            className="metrics__tag"
            style={{ backgroundColor: '#F1F5FA' }}
          >
            Evento mensal
          </span>
          <div className="metrics__news-header">
            <span className="metrics__news-header--title"> {achievementRanking?.regional?.name}</span>
            <div
              className="card__progress u-mb-0"
              style={{ width: '100%' }}
            >
              <div
                className="card__progress-container"
                style={{ margin: '12px 0' }}
              >
                <div
                  className="card__progress-bar"
                  role="progressbar"
                  style={{ width: `${parseInt((achievementRanking?.['total-score'] * 100) / achievementRanking?.['required-score'])}%`, backgroundColor: '#6FCF97' }}
                  aria-valuenow={parseInt((achievementRanking?.['total-score'] * 100) / achievementRanking?.['required-score'])}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
            <div className="metrics__news-header--points">
              <span>
                {achievementRanking?.['total-score']} / {achievementRanking?.['required-score']} pts
              </span>
              <b>Ajude a GRE a chegar a {achievementRanking?.['required-score']} e ganhe 100pts </b>
            </div>
          </div>
          <div className="metrics--list">
            {achievementRanking?.['last-achievement-users'].map(item => {
              return (
                <div className="card metrics__news-card">
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    {' '}
                    <div className="metrics__news-card-img">
                      <img
                        src={item?.user?.image?.url ?? userAvatar}
                        alt={'imagem'}
                      />

                      <span>Nível {item?.['milestone-level']?.['level-order']}</span>
                    </div>
                    <p>
                      {' '}
                      <b>{item?.user?.name}</b> ganhou <b>{item?.achievement?.['base-score']} pts</b> na missão <b>{item?.achievement?.['title']}</b>
                    </p>
                  </div>

                  <div className="medal medal--sm">
                    <img
                      src={moreMedal}
                      alt="nova medalha"
                      className="medal__img"
                    />
                    <span className="medal__value">
                      <AiOutlineTrophy />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
