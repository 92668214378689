import React from 'react';
import InstructionWidget from 'app/components/Widget/InstructionWidget';
import signature from 'app/images/signature.svg';
import photoCellPhone from 'app/images/photo-cell-phone.svg';
import add from 'app/components/EmptyState/img/add.svg';
import essayText from 'app/images/essay-text.png';

export default function EssaySendTips({ essayId }) {
  const instructions = [
    {
      number: 1,
      title: 'Escreva sua redação',
      description: 'Baixe e imprima nossa folha no botão abaixo ou utilize um folha pautada no tamanho A4.',
      img: signature,
      cta: {
        text: 'Baixar folha',
        to: `/redacao/impressao/${essayId}`
      }
    },
    {
      number: 2,
      title: 'Tire foto da redação para que ela seja digitalizada e envie pelo botão abaixo, mas antes atente para algumas instruções: ',
      description: [
        'Faça um bom enquadramento e ajuste o foco da câmera;',
        'Não corte as linhas que limitam o espaço da redação;',
        'Escolha um lugar plano e claro para tirar a foto; ',
        'Escreva com letra legível e evite  rasuras e;',
        'Padronize os espaços entre as palavras'
      ],
      type: 'list',
      img: photoCellPhone
    },
    {
      number: 3,
      title: 'Revise o texo que doi digitalizado',
      description: 'Verifique se o texto que você escreveu foi digitalizado corretamente. Se precisar editar, você pode clicar no texto e fazer a correção. Quando tudo estiver certo, clique em “Enviar para correção”. ',
      img: essayText
    }
  ];

  return (
    <>
      <h3 className="page__aside-title">Como enviar minha redação?</h3>

      <div className="instruction-widget-wrapper">
        {instructions.map(item => (
          <InstructionWidget
            data={item}
            key={item.id}
          />
        ))}
      </div>
    </>
  );
}
