import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { Link, navigate } from '@reach/router';
import { FiCamera, FiChevronLeft, FiTrash2 } from 'react-icons/fi';
import EssaySendTips from './EssaySendTips';
import promiseRequest from 'app/utils/promiseToast';
import BreadCrumbs from 'app/components/BreadCrumbs';
import imgTia from 'app/components/TIA/img/tia-avatar.png';
import Avatar from 'app/components/Avatar';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './styles.module.scss';
import { SimpleModal } from 'app/components/Modal';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function EssaySendContent({ essayId }) {
  const [imageLabel, setImageLabel] = useState();
  const [editingText, setEditingText] = useState();
  const [openLoaderModal, setOpenLoaderModal] = useState(false);
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(true);
  const textareaRef = useRef(null);
  const { promiseToast, isSubmitting } = promiseRequest();
  const appname = process.env.REACT_APP_NAME;
  const client = useClient();
  const [essayText, setEssayText] = useState('');
  const [essayStudentId, setEssayStudentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [submissionsNumber, setSubmissionsNumber] = useState();

  const essaySendForm = useFormik({
    initialValues: {
      image: ''
    }
  });

  const getEssayStatus = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/essays/${essayId}`);
    if (error) {
      toast.error('Erro ao buscar status da redação');
    } else {
      const status = data['essay-student'].status;
      const essayStudentId = data['essay-student'].id;
      setSubmissionsNumber(data['essay-student']?.['submission_order']);
      setEssayStudentId(data['essay-student']?.id);

      if (status === 'ocr_review') {
        const { data, error } = await client.fetch(`/essays/${essayId}/essay_students/${essayStudentId}`);
        if (error) {
          toast.error('Erro ao buscar status da redação');
        } else {
          setEssayText(data.body);
          setImageLabel(data['essay_image'].url);
        }
      }
    }
    setLoading(false);
  };

  console.log(essayStudentId);

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setOpenLoaderModal(true);

    const _handleReaderLoaded = readerEvt => {
      let bynaryString = readerEvt.target.result;
      essaySendForm.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
    };

    if (file) {
      setImageLabel(URL.createObjectURL(file));
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }

    setOpenLoaderModal(false);
  };

  const transcribeEssay = async () => {
    setOpenLoaderModal(true);
    const { data, error } = await client.mutate(`/essays/${essayId}/essay_students`, { essay_image: essaySendForm?.values?.image });

    if (error) {
      toast.error('Erro ao enviar imagem da redação.');
    } else {
      setEssayText(data.body);
      setEssayStudentId(data.id);
    }
    setOpenLoaderModal(false);
  };

  useEffect(() => {
    if (essaySendForm.values.image) {
      transcribeEssay();
    }
  }, [essaySendForm.values]);

  const sendEssay = async () => {
    promiseToast({
      url: `/essays/${essayId}/essay_students/${essayStudentId}/correction`,
      request: { essay_student_content_attributes: { body: essayText } },
      successText: 'Redação enviada com sucesso',
      errorText: 'Erro ao enviar redação',
      onSuccess: () => navigate(`/redacao/sobre/${essayId}`)
    });
  };

  const editText = () => {
    setEditingText(true);
    setTimeout(() => {
      textareaRef.current?.focus();
    }, 500);
  };

  useEffect(() => {
    getEssayStatus();
  }, []);

  return (
    <div>
      <div className="main-sidebar">
        <div className="main-sidebar__container ">
          <div className="filter-bar">
            <Link
              to={'/redacao'}
              className="filter-bar__back"
            >
              <div className="card__icon-wrapper">
                <FiChevronLeft />
              </div>
              <span className="filter-bar__back-container">
                <span className="filter-bar__title">Enviando a redação</span>
              </span>
            </Link>
          </div>
          <div className="tia">
            <Avatar
              className="avatar--md"
              src={imgTia}
              alt="T.I.A"
              data-tooltip-id="tia"
              data-tooltip-place="top-start"
              data-tooltip-variant="light"
            />
          </div>
          <div className={styles['text-balloon']}>
            <span className={styles['text-balloon-title']}>Enviando a redação</span>
            <p>Escreva seu texto em nossa folha de redação (baixe e imprima no botão abaixo) ou utilize um folha pautada no tamanho A4. Depois envie a foto da redação clicando no botão ao lado “Enviar foto da redação”.</p>
            <a
              href={appname === 'canaleducacao' ? '/folha_de_redacao_laboratorio.pdf' : '/redação.pdf'}
              download={appname === 'canaleducacao' ? '/folha_de_redacao_laboratorio.pdf' : '/redação.pdf'}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--outline btn--wide"
            >
              Baixar folha
            </a>
          </div>
        </div>
      </div>
      <div className={styles['page-container']}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={styles['page-container--header']}>
              {imageLabel && (
                <button
                  className="btn btn--primary"
                  onClick={sendEssay}
                >
                  Enviar para revisão
                </button>
              )}
            </div>
            <div className={styles['page-container--wrapper']}>
              {imageLabel ? (
                <div className={styles['box-container']}>
                  <div className={styles['box-container--header']}>
                    <span className={styles['box-container--header-title']}>Foto da sua redação</span>
                    {submissionsNumber < 3 && (
                      <label className="form__custom-file-input u-d-inline-block">
                        <input
                          type="file"
                          name="image"
                          id="image"
                          accept=".jpeg, .png, .jpg"
                          onChange={handleChangeImage}
                        />
                        <span style={{ textDecoration: 'underline' }}>Enviar outra foto</span>
                      </label>
                    )}
                  </div>
                  <div className="skill-evaluation__comment-item">
                    <img
                      src={imageLabel}
                      alt="imagem da redação"
                      className="u-cursor-pointer"
                      onClick={() => setOpenImgModal(true)}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles['box-container']}>
                  <div className={styles['box-container--form']}>
                    <div className={styles['box-container--content']}>
                      <label className="form__custom-file-input ">
                        Clique no botão abaixo para enviar sua redação
                        <input
                          type="file"
                          name="image"
                          id="image"
                          accept=".jpeg, .png, .jpg"
                          onChange={handleChangeImage}
                          disabled={imageLabel}
                        />
                        <span className={`btn btn--primary btn--wide ${imageLabel && 'disabled'}`}>
                          <FiCamera />
                          Enviar foto da redação
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {essayText && (
                <div className={styles['box-container']}>
                  <div className={styles['box-container--header']}>
                    <span className={styles['box-container--header-title']}>Revise o texto digitalizado</span>
                  </div>
                  <div
                    className={styles['box-container--text-area']}
                    onClick={editText}
                    onBlur={() => setEditingText(false)}
                  >
                    {editingText ? (
                      <textarea
                        ref={textareaRef}
                        // value={essayText}
                        onBlur={() => setEssayText(textareaRef.current?.value)}
                      >
                        {essayText}
                      </textarea>
                    ) : (
                      <p>{essayText}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <SimpleModal
        show={false}
        onClose={() => setOpenErrorModal(false)}
        contentClassName="simple-modal__content--sm"
      >
        <div style={{ textAlign: 'center' }}>
          <span className={styles['modal-title']}>Arquivo inválido</span>
          <p>A imagem enviada não parecer ser de uma redação. Envie uma imagem válida para seguirmos com a correção</p>
          <label className="form__custom-file-input u-d-inline-block">
            <input
              type="file"
              name="image"
              id="image"
              accept=".jpeg, .png, .jpg"
              onChange={handleChangeImage}
            />
            <span className="btn btn--primary btn--wide">Enviar outra foto</span>
          </label>
        </div>
      </SimpleModal>
      <SimpleModal
        show={openImgModal}
        onClose={() => setOpenImgModal(false)}
        contentClassName="simple-modal__content--lg"
      >
        <img
          src={imageLabel}
          alt="prova digitalizada"
        />
      </SimpleModal>
      <SimpleModal
        show={openLoaderModal}
        onClose={() => setOpenLoaderModal(false)}
      >
        <div className={styles['modal']}>
          <Avatar
            className="avatar--md"
            src={imgTia}
            alt="T.I.A"
            data-tooltip-id="tia"
            data-tooltip-place="top-start"
            data-tooltip-variant="light"
          />
          <div>
            <span className={styles['modal-title']}>Aguarde enquanto digitalizamos sua redação</span>
            <p>Não feche essa tela</p>
            <Loader />
          </div>
        </div>
      </SimpleModal>
    </div>
  );
}
